import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify"
import i18n from "i18n"
import {
  GET_TBL_STORE,
  GET_TBL_MATERIAL,
  GET_TBL_EMP,
  GET_STOCK_BALANCE_FILTERED_BY_STOCK,
  GET_STOCK_BALANCE_FILTERED_BY_MATERIAL,
  GET_SAFE_BALANCE,
  GET_NET_PROFIT,
  GET_DAY_TRANS,
  GET_MONTH_TRANS,
  GET_SUPPLIER_ACCOUNT,
  GET_ITEMS_SALES,
  GET_EXPENSE_REPORT,
  PUT_EXPENSE_CAT_REPORT,
  GET_SUPPLIER_PAYMENTS,
  GET_SHIFT_REPORT,
  GET_ONE_SAFE_DATA,
  GET_SAFE_TRANSFER,
  POST_SUPPLIER_PAYMENT
} from "./actionTypes";

import {
  getTblStoreSuccess,
  getTblStoreFail,
  getTblMaterialSuccess,
  getTblMaterialFail,
  getTblEmpSuccess,
  getTblEmpFail,
  getStockBalanceFilteredByStockSuccess,
  getStockBalanceFilteredByStockFail,
  getStockBalanceFilteredByMaterialSuccess,
  getStockBalanceFilteredByMaterialFail,
  getSafeBalanceSuccess,
  getSafeBalanceFail,
  getNetProfitSuccess,
  getNetProfitFail,
  getDayTransSuccess,
  getDayTransFail,
  getMonthTransSuccess,
  getMonthTransFail,
  getSupplierAccountSuccess,
  getSupplierAccountFail,
  getItemsSalesSuccess,
  getItemsSalesFail,
  getExpenseReportSuccess,
  getExpenseReportFail,
  putExpenseCatReportSuccess,
  putExpenseCatReportFail,
  getSupplierPaymentsSuccess,
  getSupplierPaymentsFail,
  getShiftReportSuccess,
  getShiftReportFail,
  getOneSafeDataSuccess,
  getOneSafeDataFail,
  getSafeTransferSuccess,
  getSafeTransferFail,
  postSupplierPaymentSuccess,
  postSupplierPaymentFail
} from "./actions";

import {
  getTblStore,
  getTblMaterial,
  getTblEmp,
  getStockBalanceFilteredByStock,
  getStockBalanceFilteredByMaterials,
  getSafeBalance,
  getNetProfit,
  getDayTrans,
  getMonthTrans,
  getSupplierAccount,
  getItemsSales,
  getExpenseReport,
  putExpenseCategoryReport,
  getSupplierPayments,
  getShiftReport,
  getOneSafeData,
  getSafeTransfere,
  postSupplierPay
} from "helpers/backend_helper";

// Utils saga
function* onGetTblStore() {
  try {
    const response = yield call(getTblStore);
    yield put(getTblStoreSuccess(response.rs.data));
  } catch (error) {
    yield put(getTblStoreFail(error));
  }
}

function* onGetTblMaterial() {
  try {
    const response = yield call(getTblMaterial);
    yield put(getTblMaterialSuccess(response.rs.data));
  } catch (error) {
    yield put(getTblMaterialFail(error));
  }
}

function* onGetTblEmp({ payload: parameters }) {
  try {
    const response = yield call(getTblEmp, parameters);
    yield put(getTblEmpSuccess(response.rs.data));
  } catch (error) {
    yield put(getTblEmpFail(error));
  }
}

// Main saga
function* onGetStockBalanceFilteredByMaterial({ payload: parameters }) {
  try {
    const response = yield call(getStockBalanceFilteredByMaterials, parameters);
    yield put(getStockBalanceFilteredByMaterialSuccess(response));
  } catch (error) {
    yield put(getStockBalanceFilteredByMaterialFail(error));
  }
}

function* onGetStockBalanceFilteredByStock({ payload: parameters }) {
  try {
    const response = yield call(getStockBalanceFilteredByStock, parameters);
    yield put(getStockBalanceFilteredByStockSuccess(response));
  } catch (error) {
    yield put(getStockBalanceFilteredByStockFail(error));
  }
}

function* onGetSafeBalance() {
  try {
    const response = yield call(getSafeBalance);
    yield put(getSafeBalanceSuccess(response));
  } catch (error) {
    yield put(getSafeBalanceFail(error));
  }
}

function* onGetNetProfit({ payload: parameters }) {
  try {
    const response = yield call(getNetProfit, parameters);
    yield put(getNetProfitSuccess(response));
  } catch (error) {
    yield put(getNetProfitFail(error));
  }
}

function* onGetDayTrans({ payload: parameters }) {
  try {
    const response = yield call(getDayTrans, parameters);
    yield put(getDayTransSuccess(response));
  } catch (error) {
    yield put(getDayTransFail(error));
  }
}

function* onGetMonthTrans({ payload: parameters }) {
  try {
    const response = yield call(getMonthTrans, parameters);
    yield put(getMonthTransSuccess(response));
  } catch (error) {
    yield put(getMonthTransFail(error));
  }
}

function* onGetSupplierAccount({ payload: parameters }) {
  try {
    const response = yield call(getSupplierAccount, parameters);
    yield put(getSupplierAccountSuccess(response));
  } catch (error) {
    yield put(getSupplierAccountFail(error));
  }
}

function* onGetItemsSales({ payload: parameters }) {
  try {
    const response = yield call(getItemsSales, parameters);
    yield put(getItemsSalesSuccess(response));
  } catch (error) {
    yield put(getItemsSalesFail(error));
  }
}

function* onGetExpenseReport({ payload: parameters }) {
  try {
    const response = yield call(getExpenseReport, parameters);
    yield put(getExpenseReportSuccess(response));
  } catch (error) {
    yield put(getExpenseReportFail(error));
  }
}

function* onPutExpenseCategoryReport({ payload: parameters }) {
  try {
    // const response = yield call(putExpenseCategoryReport, parameters);
    console.log(parameters)
    const response = "success"
    yield put(putExpenseCatReportSuccess(response));
  } catch (error) {
    yield put(putExpenseCatReportFail(error));
  }
}

function* onGetSupplierPayments({ payload: parameters }) {
  try {
    const response = yield call(getSupplierPayments, parameters);
    yield put(getSupplierPaymentsSuccess(response));
  } catch (error) {
    yield put(getSupplierPaymentsFail(error));
  }
}

function* onGetShiftReport({ payload: parameters }) {
  try {
    const response = yield call(getShiftReport, parameters);
    yield put(getShiftReportSuccess(response));
  } catch (error) {
    yield put(getShiftReportFail(error));
  }
}
function* onGetOneSafeData({ payload: parameters }) {
  try {
    const response = yield call(getOneSafeData, parameters);
    yield put(getOneSafeDataSuccess(response));
  } catch (error) {
    yield put(getOneSafeDataFail(error));
  }
}

function* onGetSafeTransfer() {
  try {
    const response = yield call(getSafeTransfere);
    yield put(getSafeTransferSuccess(response));
  } catch (error) {
    yield put(getSafeTransferFail(error));
  }
}

function* onPostSupplierPayment({ payload: parameters }) {
  try {
    const response = yield call(postSupplierPay, parameters);
    yield put(postSupplierPaymentSuccess(response));
    if (response) {
      toast.dismiss()
      toast.success(i18n.t("Value paid successfully"))
      // yield* onGetSupplierAccount();

    }
  } catch (error) {
    toast.dismiss()
    toast.error(i18n.t("Something Error"))
    yield put(postSupplierPaymentFail(error));
  }
}


function* reportsSaga() {
  yield takeEvery(GET_TBL_STORE, onGetTblStore);
  yield takeEvery(GET_TBL_MATERIAL, onGetTblMaterial);
  yield takeEvery(GET_TBL_EMP, onGetTblEmp);
  yield takeEvery(GET_STOCK_BALANCE_FILTERED_BY_STOCK, onGetStockBalanceFilteredByStock);
  yield takeEvery(GET_STOCK_BALANCE_FILTERED_BY_MATERIAL, onGetStockBalanceFilteredByMaterial);
  yield takeEvery(GET_SAFE_BALANCE, onGetSafeBalance);
  yield takeEvery(GET_NET_PROFIT, onGetNetProfit);
  yield takeEvery(GET_DAY_TRANS, onGetDayTrans);
  yield takeEvery(GET_MONTH_TRANS, onGetMonthTrans);
  yield takeEvery(GET_SUPPLIER_ACCOUNT, onGetSupplierAccount);
  yield takeEvery(GET_ITEMS_SALES, onGetItemsSales);
  yield takeEvery(GET_EXPENSE_REPORT, onGetExpenseReport);
  yield takeEvery(PUT_EXPENSE_CAT_REPORT, onPutExpenseCategoryReport);
  yield takeEvery(GET_SUPPLIER_PAYMENTS, onGetSupplierPayments);
  yield takeEvery(GET_SHIFT_REPORT, onGetShiftReport);
  yield takeEvery(GET_ONE_SAFE_DATA, onGetOneSafeData);
  yield takeEvery(GET_SAFE_TRANSFER, onGetSafeTransfer);
  yield takeEvery(POST_SUPPLIER_PAYMENT, onPostSupplierPayment);
}

export default reportsSaga;
