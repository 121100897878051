import { post as postAuth, get as getAuth, postImg } from "./axios_auth_api"
import { post as postNonAuth, get as getNonAuth } from "./axios_api"

//////// Buffalo Login ////////////
export const postLogin = async data => {
  try {
    return await postNonAuth("Login", data).then(response => response)
  } catch ({ response }) {
    return response
  }
}

export const getItems = async () =>
  await getAuth("TblItem/getPage?pageSize=1000")

export const postItem = async data => {
  const res = await postAuth("Adminweb/SaveUpdateProductWithIngerdiants", data)
  return res
}

export const getItemNotes = async itemId => {
  const res = await getAuth(`TblItemNote/getPage?userQuery=fkItemId=${itemId}`)
  return res
}

export const getItemIngrediant = async itemId => {
  const res = await getAuth(
    `Adminweb/GetProductIngrediants?userQuery=fk_itemId=${itemId}`
  )
  return res
}

// Tabel Safe
export const getTabelSafe = async () =>
  await getAuth("TblSafe/getPage?userQuery=safe_type=company")

// Expense
export const addExpense = async expense =>
  await postAuth("Adminweb/Expenses", expense)

export const uploadExpenseImage = async (expenseId, file) =>
  await postAuth(
    `FileUpload/UploadExpensesImages?Expense_ID=${expenseId}`,
    file,
    { headers: { "Content-Type": "multipart/form-data" } }
  )

// End Shift
export const getShiftEmployees = async () =>
  await getAuth("Adminweb/GetEmpsforEndShift")
export const getShiftDetails = async FK_Shift_ID =>
  await getAuth(
    `Adminweb/GetOpenShiftDetails?userquery=FK_Shift_ID=${FK_Shift_ID}`
  )
export const getManagerApproval = async credentials =>
  await postAuth(`Adminweb/ManagerApproval`, credentials)

export const setEndShiftEmpId = async body => {
  const res = await postAuth("Adminweb/EndShift", body)
  return res
}

export const getEmails = async () => await getAuth("Email/GetEmailsTo")
export const sendEmail = async body =>
  await postAuth(`Email/SendEmail`, body, {
    headers: { "Content-Type": "multipart/form-data" },
  })

// Suppliers
export const getTblSuppliers = async () =>
  await getAuth("TblSupplier/getPage?pageNo=0&pageSize=1000")
export const getTblSupplierPayment = async () =>
  await getAuth("TblSupplierPayment/getPage?pageNo=0&pageSize=1000")
// export const getTblSupplierPayment = async () => await getAuth('TblSupplierPayment/getPage?pageNo=0&pageSize=1000&userQuery=dateFrom=2022-01-01&dateTo=2023-12-31');

export const getMaterials = async () => {
  const res = await getAuth(`TblMaterial/getPage?pageSize=10000`)
  return res
}

export const postMaterial = async data => {
  const response = await postAuth("TblMaterial/save", data)
  return response
}

// we can send PK_Material_ID = 0 to get all materials  and Component data
export const getOneMaterial = async id => {
  return await getAuth(
    `AdminWeb/GetMaterialData?userQuery=PK_Material_ID=${id}`
  )
}

export const getOneMaterialComponent = async id => {
  return await getAuth(
    `AdminWeb/GetComponentComposition?userQuery=FK_ComponentID=${id}`
  )
}

export const UpdateComponentsAndMaterials = async data => {
  return await postAuth(`AdminWeb/UpdateComponentsAndMaterials`, data)
}

export const getMaterialComponents = async () => {
  const res = await getAuth(`TblMaterialComponent/getPage?pageSize=10000`)
  return res
}

export const postComponent = async data => {
  const res = await postAuth(
    `Adminweb/SaveComponentAndComponentComposition`,
    data
  )
  return res
}

export const getPrepareAreas = async () => {
  const res = await getAuth(`TblPreparArea/getPage?pageSize=1000`)
  return res
}

export const postPrepareArea = async data => {
  const response = await postAuth("TblPreparArea/save", data)
  return response
}

export const getCategories = async () => {
  const res = await getAuth("TblItemCategory/getPage?pageSize=1000")
  return res
}

export const postCategory = async data => {
  const response = await postAuth("TblItemCategory/save", data)
  return response
}
export const postItemImage = async data => {
  const res = await postImg(
    `FileUpload/UploadItemImages?Item_ID=${data.itemId}`,
    data.img
  )
  return res
}

export const postItemNotes = async data => {
  const response = await postAuth("Adminweb/SaveUpdateItemNotes", data)
  return response
}

export const getUnits = async () => {
  const response = await getAuth("TblUnit/getPage")
  return response
}
export const postUnit = async data => {
  const response = await postAuth("TblUnit/save", data)
  return response
}
// Reports
// -- Utils
export const getTblStore = async () =>
  await getAuth(`TblStore/getPage?pageNo=0&pageSize=10000`)
export const getTblMaterial = async () =>
  await getAuth(`TblMaterial/getPage?pageNo=0&pageSize=10000`)
export const getTblEmp = async parameters =>
  await getAuth(
    `TblEmp/getPage?pageNo=0&pageSize=10000&userQuery=${parameters || ""}`
  )
// -- Main
export const getNetProfit = async parameters =>
  await getAuth(`TrainingReports/ProfitInDuration${parameters || ""}`)
export const getDayTrans = async parameters =>
  await getAuth(`TrainingReports/DayTrans${parameters || ""}`)
export const getMonthTrans = async parameters =>
  await getAuth(`TrainingReports/MonthTrans${parameters || ""}`)
export const getSafeBalance = async () =>
  await getAuth(`AdminWeb/SafeBalanceReport`)
export const getStockBalanceFilteredByStock = async parameters =>
  await getAuth(`AdminWeb/GetStockBalancebyStore?userQuery=${parameters || ""}`)
export const getStockBalanceFilteredByMaterials = async parameters =>
  await getAuth(`AdminWeb/GetStockBalancebyItem?userQuery=${parameters || ""}`)
export const getOneSafeData = async data =>
  await getAuth(
    `AdminWeb/GetSafeData?FK_Safe_ID=${data.PK_Safe_ID}&SafeType=${data.safe_type}`
  )

export const getSupplierPayments = async parameters =>
  await getAuth(`TrainingReports/SupplierPaymentReport${parameters || ""}`)
export const getExpenseReport = async parameters =>
  await getAuth(`TrainingReports/PaymentsReport${parameters || ""}`)

export const putExpenseCategoryReport = async parameters =>
  await postAuth(`TrainingReports/PaymentsReport${parameters || ""}`)

export const getItemsSales = async parameters =>
  await getAuth(`TrainingReports/ItemReport${parameters || ""}`)
export const getSupplierAccount = async parameters =>
  await getAuth(`TrainingReports/SupplierAccount${parameters || ""}`)
export const getShiftReport = async parameters =>
  await getAuth(`TrainingReports/ShiftAllDetailsReport${parameters || ""}`)

// end shift report
export const getShiftInPreiod = async parameters =>
  await getAuth(`TrainingAdmin/GetShiftsInPeriod${parameters || ""}`)
export const getShiftDetailsByShiftId = async FK_Shift_ID =>
  await getAuth(
    `AdminWeb/GetOpenShiftDetails?userquery=FK_Shift_ID=${FK_Shift_ID}`
  )

// Safe Transfer
export const getSafeList = async () =>
  await getAuth(`TblSafe/getPage?pageSize=10000`)
export const addSafeTransfer = async body =>
  await postAuth(`TblSafeTransfere/save`, body)
export const uploadSafeTransferImage = async (safeTransferId, file) =>
  await postAuth(
    `FileUpload/SafeTransferAttachment?Safetransfer_ID=${safeTransferId}`,
    file,
    { headers: { "Content-Type": "multipart/form-data" } }
  )

// Purchase
export const saveBuyMaterial = async body =>
  await postAuth(`AdminWeb/SaveBuyMaterial`, body)
export const addNewSupplier = async body =>
  await postAuth(`TblSupplier/save`, body)

// get All Safe tranfere
export const getSafeTransfere = async () =>
  await getAuth(`AdminWeb/ViewsafeTransfere`)

// Component manufacturing permission

export const postCompManufacture = async data =>
  await postAuth("AdminWeb/SaveManufacturingPermission", data)

export const postSupplierPay = async data =>
  await postAuth("AdminWeb/InsertSupplierPayment", data)

export const postExpenseCat = async data =>
  await postAuth("TblPaymentCategory/save", data)

export const getExpenseCat = async () =>
  await getAuth("TblPaymentCategory/getPage")

export const getStockTransferCheckStoreQty = async params =>
  await getAuth(`AdminWeb/CheckStoreQty?storeFrom=${params.storeFrom}&pkMaterialId=${params.pkMaterialId}&qty=${params.qty}`)

export const postStockTransferData = async data =>
  await postAuth("AdminWeb/SaveStockTransfere", data)

export const getStockTransfer = async () =>
  await getAuth('AdminWeb/GetStockTransfere')

export const getStockTransferById = async (params) =>
  await getAuth(`AdminWeb/GetStockTransfereDetails?StockTransfere_ID=${params || ""}`)


// Privillage
export const getUserMainMenuPrivillage = async params =>
  await getAuth(`AdminWeb/GetMainPrivileges?Emp_ID=${params}`)

export const getUserSubMenuPrivillage = async params =>
  await getAuth(`AdminWeb/GetChildPagesPrivileges?Emp_ID=${params.empId}&ParentPageID=${params.mainPageId}`)

export const getPageActionPrivilege = async params =>
  await getAuth(`AdminWeb/GetPageActionsPrivileges?Emp_ID=${params.empId}&PageID=${params.pageId}`)

// Setting
export const getSetting = async () => await getAuth(`AdminWeb/GetSettings`)

// Dashboard
export const getTotalStatisticsValue = async () => 
 // await getAuth(params ? `AdminWeb/GetDashBoardData?${params.InOutComePeriod}=${params.InOutComePeriodValue}&DailyTimeLine=true` : `AdminWeb/GetDashBoardData`)
  await getAuth('AdminWeb/GetDashBoardUpperData')

export const getDashboardInOutCome = async (params) => 
  await getAuth(`AdminWeb/GetDashBoardInOutCome?${params.InOutComePeriod}=${params.InOutComePeriodValue}`)

export const getDashboardDineinValues = async () =>
  await getAuth(`/AdminWeb/GetDashBoardDinInData`)

export const getTimeline = async (params) => 
  await getAuth(`AdminWeb/GetDashBoardTimeLine?${params.timeLine}=${params.timeLineValue}`)