import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import TableContainer from "components/Common/TableContainer"

import { Col, Row, Card, CardBody, Spinner, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getSafeBalance, getOneSafe } from "store/actions"
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf"
import Breadcrumbs from "components/Common/Breadcrumb"
import CompDetailsModal from "./CompDetailsModal"

const SafeBalance = props => {
  const [compDetailsShowModal, setCompDetailsShowModal] = useState(false)
  const [compDetails, setCompDetails] = useState("")
  const [safeBalanceList, setSafeBalanceList] = useState([])
  //meta title
  document.title = "Safe Balance | Reports"
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSafeBalance())
  }, [])


  const reports = useSelector(state => state.Reports)
  const { getSafeBalance: getSafeBalanceData, loadingSafeBalance: loading } = reports

  useEffect(()=>{
    if(getSafeBalanceData) {
    // const list = getSafeBalanceData.filter(safe => safe.PK_Safe_ID !== 5)
    // setSafeBalanceList(list)
    const reversed = getSafeBalanceData.reverse()
    setSafeBalanceList(reversed)
  }
  }, [getSafeBalanceData])


    const handleMaterialData = (data) => {
      dispatch(getOneSafe({PK_Safe_ID: data.PK_Safe_ID, safe_type: data.safe_type}))
      setCompDetails(data)
      handleCompDetailsToggle()
    }

  const columns = useMemo(
    () => [
      {
        Header: props.t("Name"),
        accessor: "SafeName",
        filterable: true,
        Cell: cellProps => {
          const safeIdsNoDetails = [1, 3, 4, 8]
          return <>
          
           {(cellProps.row.original.Balance && !safeIdsNoDetails.includes(cellProps.row.original.PK_Safe_ID) )? <Button
                className="btn-light btn"
                onClick={(e) => { e.preventDefault(); handleMaterialData(cellProps.row.original) }}
                type="button"
              >
                {cellProps.value || ""}
              </Button> : <div className="px-2 py-1 fw-normal">{cellProps.value}</div>}
          </>
        },
      },
      {
        Header: props.t("Balance"),
        accessor: "Balance",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
    ],
    [props.t]
  )

  const handleCompDetailsToggle = () => {
    setCompDetailsShowModal(prevState => !prevState)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Reports")}
            breadcrumbItem={props.t("Safe Balance")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="text-center py-5">
                      <Spinner className="ms-2" color="primary" />
                    </div>
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={safeBalanceList}
                      isAddOptions={false}
                      customPageSize={30}
                      allListSize={
                        safeBalanceList ? safeBalanceList.length : []
                      }
                    />
                  )}
                </CardBody>
                {safeBalanceList &&
                  (safeBalanceList.length && !loading ? (
                    <div className="mt-2 d-flex justify-content-end">
                      <SendAndPrintPdf
                        data={safeBalanceList}
                        extraData={null}
                        filteredData={["No", "SafeName", "Balance"]}
                        extraTwoColData={null}
                      />
                    </div>
                  ) : (
                    <></>
                  ))}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <CompDetailsModal handleCompDetailsToggle={handleCompDetailsToggle} compDetailsShowModal={compDetailsShowModal} compDetails={compDetails}/>
    </React.Fragment>
  )
}

SafeBalance.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SafeBalance))
