import React, { useMemo, useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import TableContainer from "../../../components/Common/TableContainer"
import {
  Col,
  Row,
  Card,
  CardBody,
  Spinner,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import { convertDate, convertParametersToString } from "../helpers"
import DateFromTo from "../shared/DateFromTo"
import SelectEmployee from "../shared/SelectEmployee"
import { useSelector, useDispatch } from "react-redux"
import { getExpenseReport, putExpenseCatReset } from "store/actions"
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf"
import AttachmentModal from "../SafeTransferReport/AttachmentModal"
import ChangeCategory from "./ChangeCategory"
import { toast } from "react-toastify"

const ExpenseReport = props => {
  document.title = "Expense Report | Reports"
  const dispatch = useDispatch()
  const [date, setDate] = useState(null)
  const [currentEmpId, setcurrentEmpId] = useState("")
  const [currentEmpName, setcurrentEmpName] = useState("")

  const [attachData, setAttachData] = useState({})
  const [attachModal, setAttachModal] = useState(false)

  const reports = useSelector(state => state.Reports)
  const {
    getExpenseReport: expenseReport,
    loadingExpenseReport: loading,
    putExpenseCatReport: putExpenseCatReport,
    PutExpenseCatReportErr: err,
  } = reports

  useEffect(() => {
    if (putExpenseCatReport !== "") {
      toast.dismiss()
      toast.success("Expense category Updated Successfully")
      dispatch(putExpenseCatReset())
    }
  }, [putExpenseCatReport])

  useEffect(() => {
    if (err !== "") {
      toast.dismiss()
      toast.error("Expense category not Updated Successfully")
      dispatch(putExpenseCatReset())
    }
  }, [err])

  useEffect(() => {
    if (!date || !currentEmpId || (currentEmpId && currentEmpId === "Select"))
      return
    let params = {}
    if (date) {
      params = { ...date }
    }

    if (currentEmpId && currentEmpId !== "Select") {
      params.empID = currentEmpId
      params.ExcludeInvoices = true // To retrieve only expenses without purchase invoices
    }
    const paramters = convertParametersToString(params)
    dispatch(getExpenseReport(paramters))
  }, [currentEmpId, date])

  const getDateFromToHandler = date => {
    setDate(date)
  }

  const onChangeEmpIdHandler = (pkEmpId, currentEmp) => {
    setcurrentEmpId(pkEmpId)
    setcurrentEmpName(currentEmp)
  }
  const toggleAttachModel = () => {
    setAttachModal(prevState => !prevState)
  }
  const handleAttachModal = data => {
    setAttachData(data)
    toggleAttachModel()
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("Name"),
        accessor: "Payment_Name",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: props.t("Category"),
        accessor: "PaymentCategory_Name",
        filterable: true,
        Cell: cellProps => {
          const { PK_PaymentCategory_ID, PK_Payment_ID } =
            cellProps.row.original
          return <>{cellProps.value || ""}</>
          // return (
          //   <ChangeCategory
          //     categoryId={PK_PaymentCategory_ID}
          //     expenseId={PK_Payment_ID}
          //   />
          // )
        },
      },
      {
        Header: props.t("Bill Date"),
        accessor: "DocDate",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value)
          const year = date.getFullYear()
          const month =
            date.getMonth() + 1 > 9
              ? date.getMonth() + 1
              : `0${date.getMonth() + 1}`
          const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
          return <>{cellProps.value ? year + "-" + month + "-" + day : ""}</>
        },
      },
      {
        Header: props.t("Record Date"),
        accessor: "PayDate",
        filterable: true,
        Cell: cellProps => {
          const date = new Date(cellProps.value)
          const year = date.getFullYear()
          const month =
            date.getMonth() + 1 > 9
              ? date.getMonth() + 1
              : `0${date.getMonth() + 1}`
          const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
          return <>{cellProps.value ? year + "-" + month + "-" + day : ""}</>
        },
      },
      {
        Header: props.t("Notes"),
        accessor: "Description",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Value"),
        accessor: "Value",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("Employee"),
        accessor: "EmpName",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Attach"),
        Cell: cellProps => {
          const { AttachmentPath } = cellProps.row.original
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              <li>
                {AttachmentPath !== null && AttachmentPath !== "" && (
                  <Button
                    type="button"
                    className="btn btn-sm btn-soft-primary"
                    onClick={() => handleAttachModal(cellProps.row.original)}
                  >
                    <i className="mdi mdi-eye-outline" id="viewtooltip" />
                    <UncontrolledTooltip placement="top" target="viewtooltip">
                      {props.t("Attach")}
                    </UncontrolledTooltip>
                  </Button>
                )}
              </li>
            </div>
          )
        },
      },
    ],
    [props.t]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Shift")}
            breadcrumbItem={props.t("Expense Report")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom ml-2">
                  <Row className="row-cols-auto gap-4 align-items-center">
                    <DateFromTo getDateFromTo={getDateFromToHandler} />
                    <SelectEmployee onChangeEmpId={onChangeEmpIdHandler} />
                  </Row>
                </CardBody>
                <CardBody>
                  {loading ? (
                    <div className="text-center py-5">
                      <Spinner className="ms-2" color="primary" />
                    </div>
                  ) : (
                    expenseReport &&
                    (expenseReport.length ? (
                      <TableContainer
                        columns={columns}
                        data={expenseReport ? expenseReport : []}
                        isGlobalFilter={false}
                        customPageSize={5}
                        allListSize={expenseReport ? expenseReport.length : 0}
                      />
                    ) : (
                      <div style={{ height: "200px" }}></div>
                    ))
                  )}
                </CardBody>
                {expenseReport &&
                  (!loading && expenseReport.length > 0 ? (
                    <div className="mt-2 d-flex justify-content-end">
                      <SendAndPrintPdf
                        data={expenseReport}
                        extraData={{
                          Date_from:
                            date && date.dateFrom
                              ? convertDate(new Date(date.dateFrom))
                              : "First",
                          Date_to:
                            date && date.dateTo
                              ? convertDate(new Date(date.dateTo))
                              : convertDate(new Date()),
                          Employee: currentEmpName || "All",
                          Items_no: expenseReport.length || 0,
                        }}
                        filteredData={[
                          "No",
                          "Payment_Name",
                          "DocDate",
                          "PayDate",
                          "Description",
                          "Value",
                          "EmpName",
                        ]}
                        extraTwoColData={null}
                      />
                    </div>
                  ) : (
                    <></>
                  ))}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <AttachmentModal
        isOpen={attachModal}
        toggle={toggleAttachModel}
        attachData={attachData}
      />
    </React.Fragment>
  )
}

ExpenseReport.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ExpenseReport))
